.bg-img-contactusSection{
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../public/assets/images/contactus/img1.jpg);
  height: 245px;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.headerImage{
  width:100px
}

.mt-150px{
  margin-top:-60px;
}
p.fnt-17 > a{
  color:inherit;
  font-weight:inherit;
}
.location-map iframe {
  width: 100%;
  height: 100%;
}