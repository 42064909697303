.sliderSection {
	width: 100%;
	position: relative;
	overflow: hidden;
}
.swiper {
	z-index: 0 !important;
}
.swiper-slide {
	text-align: center;
	font-size: 18px;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.img-box,
img {
	width: 100%;
	height: 100%;
}
.wrapper-img {
	position: relative;
}
.overlay {
	position: absolute;
	width: 100%;
	height: 600px;
	background-color: rgba(0, 0, 0, 0.65);
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.content {
	display: flex;
	flex-direction: column;
	justify-self: center;
}
.content > h2 {
	font-size: 41px;
	color: #fff;
	font-weight: 300;
	font-family: "Open Sans", sans-serif;
	margin-bottom: 25px;
	text-transform: none;
}
.content > p {
	font-size: 20px;
	color: #fff;
	line-height: 35px;
	margin-bottom: 25px;
}
a.header-requestbtn.more-infobtn {
	/* background: red; */
	align-self: center;
}
@media (min-width: 450px) and (max-width: 612px) {
	.content{
		position: absolute;
		top: 56px;
	}
}
@media (max-width: 374px) {
	.content{
		position: absolute;
		top: 4px;
	}
  }
  