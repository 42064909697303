.listing>li {
  /* list-style-type: none; */
  margin: 10px 0px;
}

.heading-h3 {
  margin-bottom: 10px;
  text-align: center;
  color: #000;
  font-size: 40px;
  font-weight: 500;
}

.centerdata {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.fnt-14 {
  line-height: 25px;
}

.subheading-h4 {
  font-size: 20px;
  width: 65%;
  text-align: center;
  margin: auto;
  margin-bottom: 0;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.subheading_h6 {
  font-size: 17px;
text-align: center;
line-height: 23px;
margin-bottom: 14px;
}

.cutmClass {
  background: #f2f2f2;
  padding: 30px;
}

.noMargin {
  margin: 0 !important;
}

.flexWrap {
  flex-wrap: wrap !important;
  display: flex !important;
  gap: 35px 0;
}

.service4-desc {
  height: 100%;
}

.featureBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageBoxFeatureBox {
  position: absolute;
  top: -83px;
}

.singleService {
  border: 1px solid #ccc;
  padding: 0 25px 24px 25px;
  height: 83%;
  background: #fff;
  transition: all .4s ease-out;
  margin-top: 60px;
}

.serviceImgArea {
  background: #fdcd00;
  width: 85px;
  padding: 10px 10px 10px 10px;
  height: 88px;
  margin-top: -25px;
}

.serviceContent {
  padding: 15px 0;
}

.serviceContent h5 {
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0px 15px 0;
  font-family: 'poppins', sans-serif;
  text-transform: none;
  color: #011327;
}
.img-responsive{
  width: 60%;
}

.serviceContent p {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
}

.singleTeam {
  margin-top: 20px;
}

.singleService:hover {
  background: #543e8f;
  transition: all .4s ease-in;
}

.singleService:hover .serviceContent h5 {
  color: #fdcd00;
}

.singleService:hover .serviceContent p {
  color: #fff;
}

.serviceSection .uvc-heading {
  padding-left: 1%;
}
.paraSection2 {
  font-size: 2px;
  line-height: 22px;
  margin-bottom: 3px;
  text-align: justify;
}


/***************************************** featureSection *****************************************************/
.paraSection {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3px;
  text-align: justify;
  word-spacing: -1px;
}


.listItemThings>li {
  margin-bottom: 0;
  background: #0000006e;
  padding: 30px;
  border-radius: 10px;
}

/***************************************** our process *****************************************************/
.history-list:before {
  top: 37%;
}

.line {
  position: relative;
}

.line:before {
  width: 100%;
  position: absolute;
  right: 0px;
  height: 1px;
  content: "";
  background: #ccc;
  left: 0px;
  top: 58%;
}

.home2 {
  position: relative !important;
}

.static-section.home4-static-section p {
  color: #fff;
  width: 75%;
  /* text-align: center; */
  margin: auto;
  margin-top: 5px;
}

.sectionHighlight>ul>li {
  width: calc(100%/3 - 20px);
  font-size: 12px;

  border: none !important;
}

.sectionHighlight {
  padding-top: 20px !important;
  padding-bottom: 50px !important;
}

.sectionHighlight>ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 100px !important;
}

.sectionHighlight img {
  width: 75px;
  height: 75px;
  margin-bottom: 15px;
}

.bg-img-contactus {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../public/assets/images/contactus/img1.jpg) no-repeat center top;
}

.clr-white {
  color: #fff;
}

.submitButton {
  background-color: #543e8f;
}

.submitButton:hover {
  color: #fff;
  background-color: #433274
}

.bgSepration {
  background: #f2f2f2;
  padding: 40px 0;
}

.clr-blue {
  color: #543e8f;
  font-weight: bold;}

.clr-black {
  color: black;
  font-weight: bold;
}

.img-responsive {
  width: 100%;
}

.nav-tabs>li {
  margin-bottom: 0 !important;
  cursor: pointer;
}

.nav-tabs>li:hover {
  background-color: var(--blue) !important;
}

.hide {
  display: none;
}

.show {
  display: initial
}

.customImage {
  width: 100% !important;
  height: 290px !important;
}

.h3Heading {
  color: #000 !important;
  text-align: center;
}

.showmorebutton {
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}



/* responsiv css */
@media(max-width:850px) {
  .sliderSection {
    height: max-content !important;
  }

  .swiper {
    margin: 0 !important;
  }

  .content>h2 {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 22px;
  }

  .content>p {
    font-size: 14px;
    line-height: 22px;
  }

  .overlay {
    height: calc(100% - 64px);
    /* margin-top: 64px; */
  }

  .boxes-icons {
    margin-bottom: 0 !important;
  }

  .header2 {
    padding: 0;
  }

  .subHeadder {
    display: none;
  }
}

@media(max-width:800px) {
  .centerdata {
    flex-direction: column
  }

  .subheading-h4 {
    margin: 0;
    width: 100%;
  }

  .responsiv-div {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .rev-clm {
    flex-direction: column-reverse;
  }

  .mt-10 {
    margin-top: 20px;
  }

  .sectionHighlight>ul>li {
    width: calc(100%/2 - 20px)
  }
}

@media (max-width:992px) {
  .rev-clm-sub{
    margin-top: 20px !important;
  }
}
@media (max-width: 768px) {
  .mb-55 {
    margin-bottom: 55px;
  }

  .pdt-30 {
    padding-top: 50px;
  }

  .pdt-30>p,
  .pdt-30>ul>li {
    text-align: justify;
  }
}

@media(max-width:450px) {
  .overlay {
    height: 164px !important;

  }

  .res-img {
    height: 200px !important;
  }

  .content {
    width: 80%;
  }

  .content>h2 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 20px;
  }

  .content>p {
    font-size: 12px;
    width: 80%;
    margin: auto;
    line-height: 20px;
  }

  .pad100-top-bottom {
    padding-top: 30px;
  }

  .sectionHighlight>ul>li {
    width: calc(100%/1 - 20px)
  }
}


.imageBoxFeatureBox img{
  width: 100px;
  height: 100px;
  border: 3px solid #f2f2f2;
  border-radius: 50%;
}