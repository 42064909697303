.socialMedia{
  position: fixed;
    top: 34%;
    left: 0;
    width: auto;
    height: auto;
    padding: 3px;
    z-index: 9999;
    background: #543e8f;
    border-radius: 0px 10px 10px 0px;
}
/* .socialMediaList > a:hover {background: #fff; } */
.socialMediaList > a:hover .header-socials i {background: #fff; }
.socialMediaList{
  display: flex;
    flex-direction: column;
}