
  .engineering_solu{
    
      background-image: url(../../../public/assets/images/img/wallpaperflare.com_wallpaper.jpg);    
      background-repeat: no-repeat;
      background-size: 100% 100%;
  
  }
  /* .design{
    background-image: url(../../../public/assets/images/img/pxfuel.jpg);    
    background-repeat: no-repeat;
    background-size: 100% 100%;
  } */
  @media (max-width: 767px) {
    
  }

  .singleService1{ 
    border: 1px solid #ccc;
    padding: 0 25px 24px 25px; 
    min-height: 282px;
    background: #f3f1f1;
     transition: all .4s ease-out;
    margin-top: 60px; 
}
.singleService1:hover{ 
  background: #543e8f;
  transition: all .4s ease-in; 
}
.singleService1:hover .serviceImgArea{background:#fff; border: 1px solid var(--gray)}
.singleService1:hover .serviceContent1 h5{ 
  color: #fff; 
}
.singleService1:hover .serviceContent1 p{ 
  color: #fff; 
}
.serviceContent1{
  padding: 15px 0;
}
.serviceContent1 h5{
  font-size: 24px;
  font-weight: 700;
  margin: 0px 0px 20px 0;
  font-family: 'Nexa'!important;
  text-transform: none;
  color: #011327;
}
.serviceContent1 p{ 
   font-size: 16px;
   font-family: 'Nexa'!important;
}

/* Engineering solution */
.singleService2{ 
  border: 1px solid #ccc;
  padding: 0 25px 24px 25px; 
  min-height: 300px;
  background: #fff;
   transition: all .4s ease-out;
  margin-top: 60px; 
}
.singleService2:hover{ 
background: #543e8f;
transition: all .4s ease-in; 
}
.singleService2:hover .serviceImgArea{background:#fff; border: 1px solid var(--gray)}
.singleService2:hover .serviceContent2 h5{ 
color: #fff; 
}
.singleService2:hover .serviceContent2 p{ 
color: #fff; 
}
.serviceContent2{
padding: 15px 0;
}
.serviceContent2 h5{
font-size: 24px;
font-weight: 700;
margin: 0px 0px 20px 0;
font-family: 'Nexa'!important;
text-transform: none;
color: #011327;
}
.serviceContent2 p{ 
 font-size: 18px;
 font-family: 'Nexa'!important;
}
.nav-tabs .dropdown ul{
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-tabs .dropdown ul li {
  min-width: 231px;
}

.nav-tabs .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.nav-tabs .dropdown ul a i {
  font-size: 12px;
}

.nav-tabs .dropdown ul a:hover,
.nav-tabs .dropdown ul .active:hover,
.nav-tabs .dropdown ul li:hover>a {
  color: #ed502e;
}

.nav-tabs .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-tabs .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.nav-tabs .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .nav-tabs .dropdown .dropdown ul {
    left: -90%;
  }

  .nav-tabs .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}
@media only screen and (max-width: 767px) {
  .tab-pane h1{
    margin-top: 19px !important;
  }
}
@media  (min-width: 768px) and (max-width:992px) {
  .tab-pane h1{
    margin-top: 19px !important;
  }
}
/* @media only screen and (min-width: 731px) and (max-width: 768px) {
 .spms_sub_container{
  display: flex;
  }
} */

