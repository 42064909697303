.bg-img{
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../public/assets/images/products/bg-img.jpg);
   height:245px; background-position: center;
  display:flex;align-items:flex-end;justify-content: center;
}

.category{
  color: #000;
  cursor: pointer;
  padding:15px;
}
.category:hover{background: var(--blue); color:#fff}
.category.active{
background: var(--blue);
  color: #FFF!important;

}

.service-right-desc img{
  width:150px;height:150px!important
}
.swiper{
  margin-bottom:40px;
}
.para{
  margin-top: 20px;
}
.para1{
  margin-top: 20px;
}
.para1 p{
  font-family: 'Nexa' !important;
font-size: 19px;
color: #575757;
margin: 0px;
padding: 0px;
line-height: 30px;
}

.para1>li{
  font-size: 18px;
    line-height: 30px;
    margin:10px 0px;
}
.para>li{
  font-size: 18px;
    line-height: 30px;
    margin:10px 0px;
}
.para>ul{display: flex;
    /* flex-direction: column; */
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 40px 0;}
.para>ul>li{
  font-size: 18px;
    margin-bottom: 5px;
    list-style: decimal;
    width:calc(100%/2);
}

.boldPara{
  font-weight: 600;
}
.boldPara p{
  font-size: 20px !important;
}
@media (max-width:991px) {
  .bg-img{
    padding-top:62px;
    align-items:center!important;
  }
  .home4-service-section{
    padding-top:30px
  }
  .mt-0-res{margin-top:0!important}
  .padding-0-res{padding-top:30px}
}

@media(max-width:620px){
  .para>ul>li{width:100%}
}