.font-18{
  font-size:18px;
  line-height:32px;
}
.center-text{
  text-align:center;
  margin-bottom:0;
}
.center-data{
  display:flex;
  align-items:center;
  justify-content: center;
}
.mt-50{
  margin-top:50px;
}
.bg-img-services{
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../public/assets/images/services/bg-img.jpg);
   height:245px; background-position: center;
    display:flex;align-items:flex-end;justify-content: center;
}
.pd-t-70{
  padding-top: 70px;
}
.headingH2{
  margin: 24px 0;
    color: #fff;
    font-size: 42px;
}
/* .pd-t-70>h5{margin: 0 !important;} */