.bg-img-consultation{
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../public/assets/images/consultingCompany/img.jpg);
  background-size: cover;
  height:245px; background-position: center;
  display:flex;align-items:flex-end;justify-content: center;
}
.nav-tabs>li:hover{
  background-color: transparent!important;
}
.storyPara {
  width:70%;
  margin: auto;
}
.storyPara > p, .storyPara > ul >li{
  font-size: 18px;
  line-height:32px;
  text-align:justify;
}
.boldText{
  font-weight:bold;
}
.storyPara>.list>li{
  text-align: start;
    margin: 10px 0;
    font-size: 18px;
    line-height: 32px;

}
.tagline{
  font-weight: bold;
    font-style: italic;
}
.headingH2{
  margin-bottom: 10px;
  font-weight: 500;
}

.flx-clm{
  flex-direction: column;
}
.flx-clm>h5{margin-bottom: 5px}
.flx-clm>p, .flx-clm>h5{
  color:#fff;
  font-size:22px;
}
.flx-wrap{flex-wrap: wrap;display: flex;gap: 30px 0; margin-bottom: 20px;}
.centerData{display: flex;align-items: center;padding:0 30px;justify-content: center;}
.mb-1{
  margin-bottom:40px;
}
p.subHeading{
  font-size: 22px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}
.bg-black{
  background-color: #000;
}
.listMenu{
  display: flex;
  flex-wrap: wrap;
}
.listMenu > li{
  list-style: none;
  color:#fff;
}
.listMenu > li > a{color:#fff;}
.listMenu > li{
  list-style: none;
  color:#fff;
}
.listMenu > li:hover{background:var(--blue)}
.listMenu > li:hover a{background:var(--blue)}
.listMenu > li > a{text-decoration: none;
    padding: 15px;
    display: block;}
.experienceList {
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
}
.experienceh3{
  margin-top:30px;
}
.experienceList > li{
  width:25%;
  display:flex;flex-direction:column;justify-content:center;align-items:center;
}
.experienceList > li > p{text-align:center;font-weight: 600;}
.experienceList > li > img{
  width:75px;
  height:auto
}
.active{
  /* background:var(--blue); */
}
.dropdown-submenu-1{
  position: absolute;
    width: 280px;
    bottom: -153.2px;
    transform: translateY(0);
    left: 0;
    padding: 0;
    display: none;
    background-color: #fff;
    box-shadow: 1px 1px 20px #00000030;
    z-index: 999999;
}
.dropdown-submenu-1 > li{
  padding:15px;list-style: none;
}
.dropdown-submenu-1 > li >a:active{
  text-decoration: none;
}

.dropdown-submenu-1 > li:hover{background:var(--gray)!important;}
.dropdown-submenu-1 > li:hover a{color:#fff}
.dropdown-submenu-1 > li > a{
  background-color:transparent !important;
  color:#000;
  text-decoration: none;
}
.subMenu-1:hover .dropdown-submenu-1{
  display:initial;
}
.ul.dropdown-submenu-1{
  
}
@media (max-width:991px) {
  .bg-img-services{
    padding-top:62px;
    align-items:center!important;
  }
  .home4-service-section{
    padding-top:30px
  }
}
@media(max-width:800px) {
  .res-container{
    padding: 0 !important;
  }
  .head-section p{
    border:none
  }
  .storyPara{
    width:90%;
  }
  .head-section{
    flex-direction: column !important;
  }
  .noborder > p{border:none}
  .bg-img-consultation{
    align-items: center;
  }
}
@media (max-width:600px) {
.experienceList{
  gap:20px 0;
}
.experienceList > li {
  width:50%;
}
}

@media(max-width:500px) {
  .noborder > p{margin-bottom: 50px;}
.imageBoxFeatureBox{top:-72px}
}
@media(max-width:400px) {
  .experienceList > li {
  width:100%;
}
}

