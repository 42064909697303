.boldPara{
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px !important;
}

.bg-img-privacy{
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../public/assets/images/services/bg-img.jpg);
   height:245px; background-position: center;
   display:flex;justify-content:center;align-items:flex-end;
}
.h3-heading{
  margin: 30px 0;
}