.img-responsive {
	width: 100px;
	margin-left: 20px;
}
a {
	text-decoration: none;
}
.centerData2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
header.header2 .header-socials i {
	width: max-content;
	height: max-content;
}
.header2 {
	background: #fff;
	position: absolute !important;
	z-index: 99;
	padding: 0 !important;
}
.topNavText {
	font-size: 12px;
}
.headerInfo {
	display: flex;
	align-items: center;
	gap: 10px;
}
.headerInfo > i {
	color: #233064;
}
.contactUsFooter li a {
	display: flex;
	align-items: center;
}
.contactUsFooter li a i {
	width: 30px !important;
	height: 30px !important;
	line-height: 30px;
	color: #fff;
}
.centerData-sp {
	display: flex;
	justify-content: space-around;
}
.contactUsFooter li a span {
	width: 100%;
}
.customHeader {
	background: #fff;
	padding: ab;
	/* right: -21px; */
	height: 100%;
	position: absolute;
}
.flex {
	display: flex;
	gap: 15px;
	align-items: center;
}

.flex > i {
	color: #fff;
}
@media (max-width: 991px) {
	.header2 {
		padding: 0;
	}
	.subHeadder {
		display: none;
	}
}
@media (max-width: 800px) {
	.res-btn {
		margin-bottom: 20px;
	}
}
.centerDatanav {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
